import(/* webpackMode: "eager", webpackExports: ["HeaderSetter"] */ "/app/app/home/header-setter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterProvider"] */ "/app/components/supervvisor/footer-nav/footer-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/components/supervvisor/footer-nav/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderProvider"] */ "/app/components/supervvisor/header-nav/header-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/components/supervvisor/header-nav/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/app/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
