"use client"

import { title } from "process"
import Link from "next/link"
import { useUser } from "@auth0/nextjs-auth0/client"

import { useHeader } from "./header-context"

export function Header() {
  const { items, activePath } = useHeader()
  const { user, isLoading } = useUser()

  // Filter navigation items based on auth status
  const navigationItems = items.filter((item) => {
    if (item.href === "/api/auth/login" && user) return false
    if (item.href === "/join" && user) return false
    if (item.href === "/api/auth/logout" && !user) return false
    if (item.href === "/dashboard" && !user) return false
    return true
  })

  return (
    <header className="flex items-center">
      <nav className="ml-auto flex items-center space-x-2 sm:space-x-4">
        {navigationItems.map((item) => (
          <a
            key={item.href}
            href={item.href}
            className={`flex items-center space-x-1 text-sm sm:space-x-2 sm:text-base ${
              activePath === item.href
                ? "text-primary"
                : "text-muted-foreground"
            }`}
          >
            {item.icon && <item.icon className="h-4 w-4" />}
            <span className="hidden sm:inline">{item.label}</span>
          </a>
        ))}
      </nav>
    </header>
  )
}
