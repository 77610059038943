"use client"

import React, { createContext, ReactNode, useContext, useState } from "react"

type FooterItem = {
  label: string
  href: string
  icon?: React.ComponentType<{ className?: string }>
}

type FooterContextType = {
  title: string
  setTitle: (title: string) => void
  items: FooterItem[]
  setItems: (items: FooterItem[]) => void
  activePath: string
  setActivePath: (path: string) => void
}

const FooterContext = createContext<FooterContextType | undefined>(undefined)

export function FooterProvider({ children }: { children: ReactNode }) {
  const [title, setTitle] = useState<string>("")
  const [items, setItems] = useState<FooterItem[]>([])
  const [activePath, setActivePath] = useState<string>("")

  return (
    <FooterContext.Provider
      value={{ title, setTitle, items, setItems, activePath, setActivePath }}
    >
      {children}
    </FooterContext.Provider>
  )
}

export function useFooter() {
  const context = useContext(FooterContext)
  if (context === undefined) {
    throw new Error("Footer must be used within a FooterProvider")
  }
  return context
}
