"use client"

import Link from "next/link"

import { SupportLink } from "@/components/supervvisor/supervvisor-support"

import { useFooter } from "./footer-context"

export function Footer() {
  const { items, activePath } = useFooter()
  return (
    <footer className="mt-auto border-t border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
      <div className="flex h-14 items-center justify-center">
        <nav className="flex flex-wrap items-center justify-center gap-2 text-sm text-gray-500 dark:text-gray-400 sm:flex-nowrap sm:gap-4">
          <Link
            href="/legal"
            className="hover:text-gray-900 dark:hover:text-gray-100"
          >
            Legal Info
          </Link>
          <Link
            href="/privacy"
            className="hover:text-gray-900 dark:hover:text-gray-100"
          >
            Privacy Policy
          </Link>
          <Link
            href="/terms"
            className="hover:text-gray-900 dark:hover:text-gray-100"
          >
            Terms of Service
          </Link>
          <Link
            target="_blank"
            href={`mailto:${process.env.SUPPORT_EMAIL}`}
            className="hover:text-gray-900 dark:hover:text-gray-100"
          >
            Customer Support
          </Link>
        </nav>
      </div>
    </footer>
  )
}
